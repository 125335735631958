const pilotAreaListPadp = () => {


    const phaseOneDate = new Date("October 21, 2024").getTime();
    const phaseTwoDate = new Date("March 24, 2025").getTime();
    const phaseThreeDate = new Date("April 22, 2025").getTime();
    //toShow set to pilot one areas as default
    let toShow: string[] = [];
    const phaseOneAreas = ['Aberdeen City', 'Argyll & Bute', 'Highland', 'Orkney', 'Shetland']
    const phaseTwoAreas = ['Angus', 'Aberdeenshire', 'Clackmannanshire', 'Dundee City', 'East Ayrshire', 'Falkirk', 'Fife', 'Moray', 'Na h-Eileanan Siar', 'North Ayrshire', 'Perth & Kinross', 'South Ayrshire', 'Stirling'];
    let today = new Date().getTime();
    // Hard coded for testing.
    // let today = new Date("August 20, 2024").getTime();



    if (today >= phaseOneDate) {
        toShow = toShow.concat(phaseOneAreas)
    }
    if (today >= phaseTwoDate) {
        toShow = toShow.concat(phaseTwoAreas, phaseTwoAreas)
    }

    return (
        <>
            <ul>
                {
                    toShow.map((phaseArea, index) => {
                        return <li key={index}>{phaseArea}</li>
                    })
                }
            </ul>
        </>
    );
}

export default pilotAreaListPadp;