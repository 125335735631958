import { Link } from "react-router-dom";

interface ChangePostcodeProps {
    thePostcode: string | undefined;
    isPCPage?: boolean;
}

const ChangePostcode = ({ thePostcode, isPCPage = false }: ChangePostcodeProps) => {
    return (
        <div className={isPCPage ? "split-change" : "change-postcode"}>
            {isPCPage ? (''):(<h2>Your postcode</h2>)}
            <p>{thePostcode}</p>
            {isPCPage ? (
                <a href={"/"}>Change</a>
            ) : (
                <Link to={`/`}>Change</Link>
            )}
        </div>
    );
};

export default ChangePostcode
