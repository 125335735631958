import { useContext } from "react";
import ChangePostcode from "../ChangePostcode/ChangePostcode";
import HeaderTitle from "../HeaderTitle/HeaderTitle";
import PostcodeContext from "../../Contexts/PostcodeContext";

const PadpOutsideScotland = () => {
    const postcode = useContext(PostcodeContext);
    let thePostcode = postcode;

    return (
        <>
            <HeaderTitle title={'This postcode is not in Scotland'}/>

            <ChangePostcode thePostcode={thePostcode}/>

            <p>You can still <a href="https://www.gov.uk/attendance-allowance" target="_blank">apply for Attendance Allowance (opens in a new tab)
           
            </a> from the Department for Work and Pensions (DWP).</p>
        </>
        )

};

export default PadpOutsideScotland;
