import { useContext } from "react";
import './UnrecognisedScotlandEngland.scss'
import ChangePostcode from "../ChangePostcode/ChangePostcode";
import HeaderTitle from "../HeaderTitle/HeaderTitle";
import PostcodeContext from "../../Contexts/PostcodeContext";

const UnrecognisedScotlandEngland = () => {
    const postcode = useContext(PostcodeContext);
    let thePostcode = postcode;

    return(
        <>
            <HeaderTitle title={'It’s not clear from your postcode if you live in Scotland or England'}/>

            <div className="main-content">
                <div className="ds_warning-text">
                    <strong className="ds_warning-text__icon" aria-hidden="true"></strong>
                    <strong className="visually-hidden">Warning</strong>
                    <div className="ds_warning-text__text">You might want to 
                        <a href="https://www.royalmail.com/find-a-postcode" target="_blank"> check that you entered the correct postcode (opens in a new tab)</a>
                    </div>
                </div>

                <ChangePostcode thePostcode={thePostcode}/>

                <p>If you've entered the correct postcode and still think you're eligible to apply you should contact Social Security Scotland by calling free on 0800 182 2222.</p>

                <p>The call centre opening times are Monday to Friday 8am to 6pm. If you're a British Sign Language user, you can use the contactSCOTLAND app to contact Social Security Scotland by video relay.</p>

                <p>ContactSCOTLAND app link: <a href="https://contactscotland-bsl.org/" target="_blank">Home - Contact Scotland BSL (opens in a new tab)</a>
                </p>
            </div>
        </>
        )
}

export default UnrecognisedScotlandEngland
