import {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import './UnrecognisedPadp.scss'
import ChangePostcode from "../ChangePostcode/ChangePostcode";
import HeaderTitle from "../HeaderTitle/HeaderTitle";
import PilotAreaListPadp from "../PilotAreaList/PilotAreaListPadp";
import ErrorSummary from "../Errors/ErrorSummary";
import PostcodeContext from "../../Contexts/PostcodeContext";

const UnrecognisedPadp = () => {
    const navigate = useNavigate()
  
    const postcode = useContext(PostcodeContext);
    let thePostcode = postcode;

    const redirect = (newPath: string) => {
        navigate(newPath, { state: {thePostcode}})
    }

    const [ radioValue, setRadioValue ] = useState('')
    const [showError, setShowError] = useState(false);

    const onSubmitRadio = (e: any) => {
        e.preventDefault();

        if(!radioValue) {
            setShowError(true);
        } else {
            if (radioValue === "yes"){
                redirect('/eligible')
            } else {
                if (radioValue === "no"){
                    redirect(`/ineligible`)
                }
            }
        }

    }

    return (
        <>
            <HeaderTitle title={'This postcode has not been recognised'}/>

            {showError &&
                (<ErrorSummary title={`There's a problem`} errorText="Please select an option before submitting."/>)
            }

            <ChangePostcode thePostcode={thePostcode}/>

            <p>This sometimes happens if the postcode has recently changed. For example, if a property is a new build the
                postcode might not be on our our database yet.</p>

            <p>You can now continue with your application if you live in:</p>

            <PilotAreaListPadp/>

            <form autoComplete="false" onSubmit={onSubmitRadio}>
                <fieldset>
                    <div className={`ds_question ${showError ? "ds_question--error" : ""}`}>
                        <h3>Do you live in one of the areas above?</h3>
                        <p className="ds_hint-text">Select an option</p>
                        {showError && <p className="ds_question__error-message">This field is required</p>}
                        <div className="ds_field-group">
                            <div className="ds_radio">
                                <input className="ds_radio__input" id="recognised-yes" name="unrecognised-type" type="radio"
                                    value="yes"
                                    onChange = {e => setRadioValue(e.target.value)}
                                    checked={radioValue === "yes"}
                                />
                                <label className="ds_radio__label" htmlFor="recognised-yes">Yes</label>
                            </div>

                            <div className="ds_radio">
                                <input className="ds_radio__input" id="recognised-no" name="unrecognised-type" type="radio"
                                    value="no"
                                    onChange = {e => setRadioValue(e.target.value)}
                                    checked={radioValue === "no"}

                                />
                                <label className="ds_radio__label" htmlFor="recognised-no">No</label>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div className="button-group">
                    <p>
                        <button className="ds_button" type="submit" >Next</button>
                    </p>
                </div>
            </form>
       

        </>
    )
}

export default UnrecognisedPadp;
