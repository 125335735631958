import React from "react";
import HeaderTitle from "../HeaderTitle/HeaderTitle";

// Bring in the DIGITAL PORTAL URL env variable
const { REACT_APP_DIGITAL_PORTAL_URL } = process.env

const PadpEligible = () => {

    return (
        <>
            <HeaderTitle title={
                <>
                    You can apply for<br/>
                    Pension Age Disability Payment with Apply Online button
                </>
            }/>

            <p>You can start your application online now.</p>

            <p>
                <button className="ds_button" onClick={() => window.open( REACT_APP_DIGITAL_PORTAL_URL, "_blank")}>Apply online</button>
            </p>

            <p>If you do not want to apply online you can apply:</p>
            <ul>
                <li>over the phone</li>
                <li>by completing and sending us a paper application form</li>
            </ul>

            <p>Call us free on 0800 182 2222 to start a phone application or to get a paper application form.</p>

            <p>The call centre opening times are Monday to Friday, 8am to 6pm.</p>
        </>
    )
}

export default PadpEligible;
